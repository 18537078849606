import React, { useEffect, useState } from "react";
import { Checkbox } from "./ActivityCheckboxes";
import Button from "../../../../components/form/button";
import Spinner from "../../../../components/form/spinner";
import Actions from "./actions";

export default function FeatureFlags({ _id, features, loading, type }) {
    const [inventory, setInventory] = useState(features?.inventory || false);
    useEffect(() => {
        setInventory(features?.inventory);
    }, [features?.inventory]);

    const [companies, setCompanies] = useState(features?.companies || false);
    useEffect(() => {
        setCompanies(features?.companies);
    }, [features?.companies]);

    const [items, setItems] = useState(features?.items || false);
    useEffect(() => {
        setItems(features?.items);
    }, [features?.items]);

    const [itemInventory, setItemInventory] = useState(
        features?.itemInventory || false
    );
    useEffect(() => {
        setItemInventory(features?.itemInventory);
    }, [features?.itemInventory]);

    const [logs, setLogs] = useState(features?.logs || false);
    useEffect(() => {
        setLogs(features?.logs);
    }, [features?.logs]);

    const [salesOrders, setSalesOrders] = useState(
        features?.salesOrders || false
    );
    useEffect(() => {
        setSalesOrders(features?.salesOrders);
    }, [features?.salesOrders]);

    const [purchases, setPurchases] = useState(features?.purchases || false);
    useEffect(() => {
        setPurchases(features?.purchases);
    }, [features?.purchases]);

    const [billOfLading, setBillOfLading] = useState(
        features?.billOfLading || false
    );
    useEffect(() => {
        setBillOfLading(features?.billOfLading);
    }, [features?.billOfLading]);

    const [packOut, setPackOut] = useState(features?.packOut || false);
    useEffect(() => {
        setPackOut(features?.packOut);
    }, [features?.packOut]);

    const [accounting, setAccounting] = useState(features?.accounting || false);
    useEffect(() => {
        setAccounting(features?.accounting);
    }, [features?.accounting]);

    const handleSubmit = (e) => {
        e.preventDefault();
        Actions.updateFeatureFlags(_id, {
            inventory,
            companies,
            items,
            itemInventory,
            logs,
            salesOrders,
            purchases,
            billOfLading,
            packOut,
            accounting,
        });
    };

    return (
        <>
            <h1>Features</h1>
            <form onSubmit={handleSubmit}>
                {type === "distributor" && (
                    <>
                        <Checkbox
                            label={"Inventory"}
                            value={inventory}
                            onChange={(e) => {
                                setInventory(e.target.checked);
                            }}
                        />
                        <br />
                    </>
                )}
                <Checkbox
                    label={"Companies"}
                    value={companies}
                    onChange={(e) => {
                        setCompanies(e.target.checked);
                    }}
                />
                <br />
                <Checkbox
                    label={"Items"}
                    value={items}
                    onChange={(e) => {
                        setItems(e.target.checked);
                    }}
                />
                <br />
                <Checkbox
                    label={"Logs on Web"}
                    value={logs}
                    onChange={(e) => {
                        setLogs(e.target.checked);
                    }}
                />
                <br />
                {type === "distributor" && (
                    <>
                        <Checkbox
                            label={"Purchases"}
                            value={purchases}
                            onChange={(e) => {
                                setPurchases(e.target.checked);
                            }}
                        />
                        <br />
                    </>
                )}
                <Checkbox
                    label={"Sales Orders"}
                    value={salesOrders}
                    onChange={(e) => {
                        setSalesOrders(e.target.checked);
                    }}
                />
                <br />
                <Checkbox
                    label={"Bill of Lading"}
                    value={billOfLading}
                    onChange={(e) => {
                        setBillOfLading(e.target.checked);
                    }}
                />
                <br />
                <Checkbox
                    label={"Pack Out"}
                    value={packOut}
                    onChange={(e) => {
                        setPackOut(e.target.checked);
                    }}
                />
                <br />
                <Checkbox
                    label={"QuickBooks"}
                    value={accounting}
                    onChange={(e) => {
                        setAccounting(e.target.checked);
                    }}
                />
                <br />
                {type === "distributor" && (
                    <Checkbox
                        label={"Item Inventory"}
                        value={itemInventory}
                        onChange={(e) => {
                            setItemInventory(e.target.checked);
                        }}
                    />
                )}

                <br />
                <br />
                <Button
                    type="submit"
                    inputClasses={{ "btn-primary": true }}
                    disabled={loading}
                >
                    Save changes
                    <Spinner space="left" show={loading} />
                </Button>
            </form>
        </>
    );
}

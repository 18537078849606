import React from "react";
import TaggingSetupForm from "./tagging-setup";
import TaggingSummary from "./tagging-summary";
import PaperRequest from "./PaperRequest";
import Button from "../../../../components/form/button.jsx";
import Actions from "./actions";

class Tagging extends React.Component {
    constructor(props) {
        super(props);
        Actions.getDistributorProducts();

        this.state = {
            edit: false,
            showPaperForm: false,
        };

        this.handleToggleEdit = this.handleToggleEdit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentWillMount() {
        if (!this.props.taggingActive) {
            this.setState({
                edit: true,
            });
        }
    }

    handleToggleEdit() {
        this.setState({
            edit: !this.state.edit,
        });
    }
    handleCancel() {
        if (!window.confirm("Are you sure?")) {
            return;
        }

        Actions.cancelTagging(
            this.props._id,
            this.props.distributorSubscriptionId
        );
    }

    sendPaper = () => {
        this.setState({ showPaperForm: !this.state.showPaperForm });
    };

    render() {
        const printers = [
            {
                name: "TSC ALPHA-30RB",
                type: "mobile",
                cost: 250,
                printerOnlyCost: 400,
            },
            {
                name: "Zebra ZQ521",
                type: "mobile",
                cost: 250,
                printerOnlyCost: 400,
            },
            {
                name: "TSC DA220",
                type: "mobile",
                cost: this.props.country === "Australia" ? 520 : 500,
                printerOnlyCost: 600,
            },
            {
                name: "Zebra ZT411",
                type: "industrial",
                cost: 736,
                printerOnlyCost: 850,
            },
            {
                name: "TSC MB240T",
                type: "industrial",
                cost: 736,
                printerOnlyCost: 850,
            },
        ];

        return (
            <div>
                <PaperRequest
                    country={this.props.country}
                    farmId={this.props._id}
                    show={this.state.showPaperForm}
                    onClose={this.sendPaper}
                    printers={this.props.printers}
                    paperCosts={this.props.paperCosts}
                    distributorSubscriptionStatus={
                        this.props.distributorSubscriptionStatus
                    }
                    nextMobileShipment={this.props.nextMobileShipment}
                    nextIndustrialShipment={this.props.nextIndustrialShipment}
                />
                {this.state.edit ? (
                    <TaggingSetupForm
                        toggleEdit={this.handleToggleEdit}
                        {...this.props}
                        printerOptions={printers}
                    />
                ) : (
                    <div>
                        <TaggingSummary
                            printerOptions={printers}
                            {...this.props}
                        />
                        <Button
                            type="submit"
                            inputClasses={{ "btn-primary": true }}
                            onClick={this.handleToggleEdit}
                            disabled={this.state.loading}
                        >
                            Edit
                        </Button>{" "}
                        {(this.props.taggingActive === true && (
                            <Button
                                type="submit"
                                inputClasses={{ "btn-primary": true }}
                                onClick={this.sendPaper}
                                disabled={this.state.loading}
                            >
                                Send more paper
                            </Button>
                        )) ||
                            null}
                        &nbsp;
                        <Button
                            type="submit"
                            inputClasses={{ "btn-secondary": true }}
                            onClick={this.handleCancel}
                            disabled={this.state.loading}
                        >
                            Cancel Subscription
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default Tagging;
